// external dependencies
import React from 'react';
import styled from 'styled-components';
import AddBoxIcon from '@material-ui/icons/AddBox';

// gatsby dependencies
import { graphql } from 'gatsby';

// internal dependencies
import SEO from '../components/SEO';
import Hero from '../components/shared/Hero';
import TextBlock from '../components/shared/TextBlock';
import Grid from '../components/shared/Grid';
import Section from '../components/shared/Section';
import Cta from '../components/shared/Cta';
import Video from '../components/shared/Video';

const StyledFlexGridWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: row wrap;
  max-height: 500px;
`;

const StyledGridItemWrapper = styled.div`
  color: ${(props) => props.theme.colors.darkGray};
  padding: 0.5rem;
  margin: 5px;
  border-radius: ${(props) => props.theme.elements.borderRadius};
  display: flex;

  span {
    margin-left: 1rem;
  }

  svg {
    fill: ${(props) => props.theme.colors.green};
  }
`;

// component definition
const WhoYouAreTemplate = ({ data }) => {
  const {
    contentBodyButtonLink,
    contentBodyButtonText,
    contentBodyHeadline,
    contentBodySupertext,
    contentBody,
    pageTitle,
    supertext,
    headline,
    metaDescription,
    heroImage,
    modules,
  } = data.customer;

  const gridItems = modules.gridItem.map((item, i) => (
    <StyledGridItemWrapper key={i}>
      <AddBoxIcon />
      <span>{item.gridItemTitle}</span>
    </StyledGridItemWrapper>
  ));

  return (
    <div>
      <SEO title={pageTitle} description={metaDescription} />
      <Hero
        short
        bkgImage={heroImage}
        superText={supertext ? supertext : 'Our Services'}
        headline={headline}
      />
      <Section>
        <Grid twoCol>
          <TextBlock
            superText={contentBodySupertext}
            headline={contentBodyHeadline}
            buttonText={contentBodyButtonText}
            buttonLink={contentBodyButtonLink && contentBodyButtonLink}
            body={contentBody.contentBody}
            extraButton
          />
          <StyledFlexGridWrapper>{gridItems}</StyledFlexGridWrapper>
        </Grid>
      </Section>
      {data.customer.video && (
        <Section>
          <Video
            headline='The leader in decorative precast concrete substation security screen walls… Nationwide!'
            videoSrc={data.customer.video.file.url}
            videoPoster={data.customer.videoPoster.file.url}
          />
        </Section>
      )}
      <Cta
        ctaSupertext={data.customer.ctaSection.supertext}
        ctaHeadline={data.customer.ctaSection.headline}
        phoneNumber={data.customer.ctaSection.phoneNumber}
        email={data.customer.ctaSection.email}
        ctaImage={data.customer.ctaSection.image}
      />
    </div>
  );
};

export const query = graphql`
  query($slug: String!) {
    customer: contentfulCustomerPageTemplate(slug: { eq: $slug }) {
      contentBodyButtonLink
      contentBodyButtonText
      contentBodyHeadline
      contentBodySupertext
      contentBody {
        contentBody
      }
      headline
      heroImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        title
      }
      pageTitle
      supertext
      metaDescription
      modules {
        gridItem {
          gridItemTitle
        }
      }
      video {
        file {
          url
        }
      }
      videoPoster {
        file {
          url
        }
      }
      ctaSection {
        email
        headline
        phoneNumber
        supertext
        image {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          title
        }
      }
    }
  }
`;

export default WhoYouAreTemplate;
