// external dependencies
import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  h3 {
    text-align: center;
    margin-bottom: 2rem;
  }
`;

const StyledVideoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;

  video {
    width: ${(props) => (props.full ? '100%' : '60%')};
    box-shadow: ${(props) => props.theme.effects.boxShadow};
    border-radius: ${(props) => props.theme.elements.borderRadius};
  }

  @media (max-width: 600px) {
    video {
      width: 100%;
      height: auto;
    }
  }
`;

// component definition
const Video = ({ videoSrc, videoPoster, full, headline }) => {
  return (
    <StyledWrapper>
      <h3>{headline}</h3>
      <StyledVideoWrapper full={full}>
        <video controls poster={videoPoster}>
          <source src={videoSrc} type='video/webm'></source>
          <source src={videoSrc} type='video/mp4'></source>
        </video>
      </StyledVideoWrapper>
    </StyledWrapper>
  );
};

export default Video;
