// external dependencies
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.twoCol
      ? 'repeat(auto-fit, minmax(40%, 1fr))'
      : 'repeat(auto-fit, minmax(300px, 1fr))'};
  column-gap: 15px;
  row-gap: 15px;
  justify-items: center;
  margin: ${(props) => (props.extraMargin ? '2rem' : 0)};

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

// component definition
const Grid = ({ children, twoCol, extraMargin, ...other }) => {
  return (
    <div {...other}>
      <StyledGrid extraMargin={extraMargin} twoCol={twoCol}>
        {children}
      </StyledGrid>
    </div>
  );
};

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  twoCol: PropTypes.bool,
};

Grid.defaultProps = {
  twoCol: false,
};

export default Grid;
