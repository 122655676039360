export const formatPhoneNumber = (phone) => {
  let formattedPhone = phone;
  if (formattedPhone.startsWith('1')) {
    formattedPhone = formattedPhone.slice(1);
  }

  if (formattedPhone.length !== 10) {
    return phone;
  }

  return `(${formattedPhone.substr(0, 3)}) ${formattedPhone.substr(
    3,
    3
  )}-${formattedPhone.substr(6, 4)}`;
};
