// external dependencies
import React from 'react';
import styled from 'styled-components';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import Grid from '@material-ui/core/Grid';

// gatsby dependencies
// import BackgroundImage from 'gatsby-background-image';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

// local dependencies
import SuperText from './SuperText';
import Form from './Form';
import { formatPhoneNumber } from '../../utils/utils';

const StyledCtaWrapper = styled.div`
  min-height: 600px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;

  @media (max-width: 800px) {
    min-height: unset;
    & .MuiGrid-root.MuiGrid-item {
      width: 100%;
    }
  }

  @media (max-width: 600px) {
    padding: 25px;
    min-height: unset;
  }
`;

const StyledBackgroundColor = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.95;
  /* this rgb is the props.theme.colors.blue value */
  background: rgb(40, 42, 115);
  background: linear-gradient(
    360deg,
    rgba(40, 42, 115, 1) 0%,
    rgba(23, 25, 89, 1) 75%
  );
`;

const StyledImageWrapper = styled.div`
  width: 100%;
  @media (max-width: 800px) {
    display: none;
  }
`;

const StyledTextWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 50px;
  color: ${(props) => props.theme.colors.white};
  max-width: 500px;

  h3 {
    margin: 1rem auto 2rem auto;
    color: ${(props) => props.theme.colors.white};
  }

  li {
    margin: 8px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 1;

    h5 {
      color: ${(props) => props.theme.colors.white};
      margin-left: 16px;
    }

    svg {
      fill: ${(props) => props.theme.colors.gold};
    }
  }

  @media (max-width: 800px) {
    padding: 0px;
  }
`;

const StyledLogoWrapper = styled.div`
  min-height: 100px;
  padding: 25px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  border-top: 1px solid rgba(40, 42, 115, 0.1);

  img {
    width: 150px;
    height: auto;
    margin: 10px;
  }
`;

// component definition
const Cta = ({
  ctaImage,
  ctaSupertext,
  ctaHeadline,
  phoneNumber,
  email,
  form,
  altText = '',
}) => {
  const data = useStaticQuery(graphql`
    query {
      certLogos: allFile(filter: { sourceInstanceName: { eq: "certs" } }) {
        nodes {
          id
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
      }
      placeholder: file(relativePath: { eq: "fence-placeholder.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
    }
  `);

  const placeholderImage = data.placeholder.childImageSharp.gatsbyImageData;

  return (
    <>
      <StyledLogoWrapper>
        {data.certLogos.nodes.map((logo, i) => (
          <GatsbyImage
            key={i}
            image={logo.childImageSharp.gatsbyImageData}
            alt={`logo-${logo.id}`}
          />
        ))}
      </StyledLogoWrapper>
      <StyledCtaWrapper>
        <StyledBackgroundColor />
        <Grid
          container
          alignItems='flex-start'
          justifyContent='center'
          wrap='wrap'
        >
          <Grid item>
            <StyledTextWrapper>
              <div>
                <SuperText title={ctaSupertext} />
                <h3>{ctaHeadline}</h3>
                <ul>
                  <li>
                    <PhoneIcon />
                    <a href={`tel:+1${phoneNumber}`}>
                      <h5>{formatPhoneNumber(phoneNumber)}</h5>
                    </a>
                  </li>
                  <li>
                    <EmailIcon />
                    <a href={`mailto:${email}`}>
                      <h5>{email}</h5>
                    </a>
                  </li>
                </ul>
              </div>
            </StyledTextWrapper>
          </Grid>
          {form ? (
            <Grid item>
              <Form formName='home-page' />
            </Grid>
          ) : (
            <Grid item xs={6}>
              <StyledImageWrapper>
                <GatsbyImage
                  image={ctaImage ? ctaImage.gatsbyImageData : placeholderImage}
                  style={{ maxHeight: '500px', width: '100%' }}
                  alt={altText}
                />
              </StyledImageWrapper>
            </Grid>
          )}
        </Grid>
      </StyledCtaWrapper>
    </>
  );
};

export default Cta;
